const PasswordValidation = ( password ) => {
    var alert_text = "";
    //check password security
    if(password.length < 8){
        alert_text += "A senha precisa ter pelo menos 8 caracteres.\n";
    }
    if(!/[a-z]/.test(password)){
        alert_text += "A senha precisa ter pelo menos uma letra minúscula.\n";
    }
    if(!/[A-Z]/.test(password)){
        alert_text += "A senha precisa ter pelo menos uma letra maiúscula.\n";
    }
    if(!/[0-9]/.test(password)){
        alert_text += "A senha precisa ter pelo menos um número.\n";
    }
    if(!/[!@#$%^&*]/.test(password)){
        alert_text += "A senha precisa ter pelo menos um caractere especial.\n";
    }
    return alert_text;
}

export default PasswordValidation;