const RegistrationForm = {
    //1:{
    //    placeholder:"Nome", type:"text", name:"username", required:true
    //},
    2:{
        placeholder:"Email", type:"email", name:"email", required:true
    },
    3:{
        placeholder:"Senha", type:"password", name:"pass", required:true
    },
    4:{
        placeholder:"Senha novamente", type:"password", name:"pass2", required:true
    },
    5:{
        btn:true, type:"submit", value:"Criar conta", required:true
    },
}

export default RegistrationForm;