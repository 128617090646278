import React from "react";
import PageForm from "../components/PageForm";
import RegistrationForm from "../components/Forms/RegistrationForm";
import Form from "../components/Form";
import { useNavigate } from "react-router-dom";
import dbQuery from "../gets/DBQuery.js";
import PasswordValidation from "../components/PasswordValidation.js";

const Registration = (props) => {

    const navigate = useNavigate();

    const handleRegistration = (event) => {
        event.preventDefault();
    
        var { email, pass, pass2 } = document.getElementsByClassName("registration-form")[0];

        var alert_text = PasswordValidation(pass.value);

        if(alert_text !== ""){
            alert(alert_text);
            return;
        }
    
        if(pass.value !== pass2.value){
            alert("As duas senhas precisam ser iguais.");
            return;
        }
    
        //send registration request
        var registration_time = new Date().toISOString();

        dbQuery("insert_user", {
            email: email.value,
            hashed_password: pass.value,
            registration_time: registration_time,
            last_access: registration_time
        }, (data)=>{
            if(data === true){
                alert("Usuário cadastrado com sucesso.");
                navigate("/login");
            }
            else{
                alert("Erro ao cadastrar usuário.");
            }
        });
    };

    var inputs = RegistrationForm;

    return (
        <div className="default-container">
            <div className="p-10">
                <PageForm backNavigation={true} title={"Criar Conta"} content={<Form className="registration-form" formFunction={handleRegistration} inputs={inputs}/>}/>
            </div>
        </div>
    )
};

export default Registration;