import React from "react";
import { useNavigate, Link } from 'react-router-dom';
import PageForm from "../components/PageForm";
import dbQuery from "../gets/DBQuery";

const Content = (props) => {
  return (
    <div>
      <form className="main-form" onSubmit={props.formFunction}>
        <div className="input-container">
            <input placeholder="Email" type="email" name="email" required />
        </div>
        <div className="input-container">
            <input placeholder="Senha" type="password" name="pass" required />
        </div>
        <div className="w-100 button-container">
            <input type="submit" className="form-btn" value="Entrar"/>
        </div>
        <div className="w-100 button-container">
            <Link to="/register" className="w-100">
                <input type="submit" className="form-btn" value="Criar conta"/>
            </Link>
        </div>
      </form>
      <div className="pt-3">
        <Link to="/recovery" className="link small-text">Esqueci minha senha</Link>
      </div>
    </div>
  )
}

const Login = (props) => {

    const navigate = useNavigate();

    var isLoggedIn = localStorage.getItem('isLoggedIn');

    if(isLoggedIn === null || isLoggedIn === undefined){
        isLoggedIn = false;
    }

    if(isLoggedIn){
        //redirect to home
        navigate("/home");
    }

    const handleLogin = (event) => {
        event.preventDefault();
    
        var { email, pass } = document.getElementsByClassName("main-form")[0];

        dbQuery("get_id_by_email", {
            email: email.value,
            hashed_password: pass.value
        }, (data)=>{
            if(data.length !== undefined && data.length === 0){
                alert("Email ou senha incorretos.");
                return;
            }
            else{
                let auth = {
                    token: data.token,
                    user_id: data.user_id
                }

                localStorage.setItem('isLoggedIn', true);
                localStorage.setItem('auth', JSON.stringify(auth));
                localStorage.setItem('user_id', data.user_id);

                navigate("/home");
            }
        });
    };

    return (
        <div className="default-container">
            <div className="p-10">
                <PageForm title={"Entrar"} content={<Content formFunction={handleLogin}/>}/>
            </div>
        </div>
    )
};

export default Login;