import { db_base_url } from "../App.js";
import fetchContent from "../gets/Fetch.js";
import { logoff } from "../components/UpperMenu.js";

const dbQuery = (query_type, values, callback) =>{

    var auth = JSON.parse(localStorage.getItem('auth'));

    if(auth === null || auth === undefined){
        auth = {"token": undefined, "user_id": undefined};
    }

    //convert dict elements to number if possible
    for (var key in values) {
        try{
            if(values[key].includes('"')){
                values[key] = values[key].replace(/"/g, '');
                values[key] = Number(values[key]);
            }
        }catch(e){}
        //for every value, add a type
        values[key] = [values[key], typeof values[key]];
    }

    let uri = db_base_url;
    let body = {
        query_type: query_type,
        values: values
    };

    if(auth.token || auth.user_id){
        body.auth = auth;
    }

    body = JSON.stringify(body);

    fetchContent(uri, body, 'POST', (data)=>{
        if(data !== null && data !== undefined){
            if(!data.logout){
                callback(data);
            }
            else{
                logoff();
                window.location.href = "/login";
            }
        }
        else{
            alert("Erro de conex���o.");
        }
    });
}

export default dbQuery;