const fetchContent = async (uri, body, method, callback = ()=>{}, max = 50) => {
    //make recursive call for api pagination if necessary

    const recursiveFetch = async (uri, body, method, last_data = {}) => {
        var resp_ok = true;
        await fetch(uri, {
            method: method,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: body
        })
        .then((resp) => {
            if(resp.status === 400){
                resp_ok = false;
            }
            return resp.json();
        })
        .then((data) => {
            if(resp_ok){
                if(data.pagination){
                    //concatenate the results
                    if(last_data.results === undefined){
                        last_data.results = [];
                    }
                    data.results = last_data.results.concat(data.results);
                }
                if(data.results && data.pagination.next_page && data.results.length < max){
                    recursiveFetch(data.pagination.links.next, body, method, data);
                }
                else{
                    callback(data);
                }
            }
        });
    }

    try{
        recursiveFetch(uri, body, method, callback);
    }
    catch(e){
        console.log(e);
    }
}

export default fetchContent;