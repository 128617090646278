import React from "react";
import jsPDF from "jspdf";
import domtoimage from 'dom-to-image';

import UpperMenu from "../components/UpperMenu.js"
import { Navigate } from "react-router-dom";
import dbQuery from "../gets/DBQuery";
import home from "../images/home.png"
import assinaturaRecibo from "../images/assinaturaRecibo.png"

const Content = (props) => {
    return (
        <form className="main-form" onSubmit={props.formFunction}>
            <div className="default-container-receipt">
                <div className="receipt-default-container-container">
                    <div className="mx-auto">
                        <div className="receipt-form bg-white receipt-default-container">
                            <div className="sub-sections flex-row">
                                <div className="first-section w-50 pr-2">
                                    <div className="title bold">RECIBO DE ALUGUEL</div>
                                    <div className="pb-4"></div>
                                    <div className="sub-sections flex-row">
                                        <div className="sub-section1 w-50 pr-2">
                                            <div className="pb-4"></div>
                                            <div className="pb-2"></div>
                                            <div className="input-container flex-row">
                                                <label className="">Nº</label>
                                                <input className="min-width-0" type="number" name="number"/>
                                            </div>
                                            <div className="pb-4"></div>
                                            <div className="pb-2"></div>
                                            <div className="d-flex">
                                                <input type="checkbox" name="commercial"/>
                                                <div>Comercial</div>
                                            </div>
                                            <div className="pb-4"></div>
                                            <div className="pb-2"></div>
                                            <div className="d-flex">
                                                <input type="checkbox" name="residential"/>
                                                <div>Residencial</div>
                                            </div>
                                        </div>
                                        <div className="sub-section2 w-50 w-100">
                                            <div className="pb-4"></div>
                                            <div className="pb-4"></div>
                                            <div className="pb-4"></div>
                                            <img className="w-100" src={home} alt="Casa" />
                                        </div>
                                    </div>
                                </div>
                                <div className="second-section w-50 pl-2">
                                    <div className="input-container flex-row m-0">
                                        <label className="w-50 bold py-05 faded-border-bottom">ALUGUEL</label>
                                        <input className="w-50" type="number" name="rent" onBlur={props.sumValues}/>
                                    </div>
                                    <div className="input-container flex-row m-0 pt-2">
                                        <label className="w-50 py-05 faded-border-bottom">Impostos/Taxas</label>
                                        <input className="w-50" type="number" name="taxes" onBlur={props.sumValues}/>
                                    </div>
                                    <div className="input-container flex-row m-0 pt-2">
                                        <label className="w-50 py-05 faded-border-bottom">Condomínio</label>
                                        <input className="w-50" type="number" name="condominium" onBlur={props.sumValues}/>
                                    </div>
                                    <div className="input-container flex-row m-0 pt-2">
                                        <label className="w-50 py-05 faded-border-bottom">Outros</label>
                                        <input className="w-50" type="number" name="others" onBlur={props.sumValues}/>
                                    </div>
                                    <div className="input-container flex-row m-0 pt-2">
                                        <div className="w-50 bold py-05 faded-border-bottom d-flex">
                                            <label>SOMA</label>
                                            <div className="flex-grow"></div>
                                            <label>R$</label>
                                        </div>
                                        <input className="sum w-50" type="text" name="sum" disabled/>
                                    </div>
                                    <div className="input-container flex-row m-0 pt-2">
                                        <div className="w-50 py-05 faded-border-bottom d-flex">
                                            <label>Desc. I.R.F</label>
                                            <div className="flex-grow"></div>
                                            <label>%</label>
                                        </div>
                                        <input className="w-50" type="text" name="desc_irf" onBlur={props.sumValues}/>
                                    </div>
                                    <div className="input-container flex-row m-0 pt-2">
                                        <div className="w-50 py-05 faded-border-bottom d-flex">
                                            <label>Líquido</label>
                                            <div className="flex-grow"></div>
                                            <label className="bold">R$</label>
                                        </div>
                                        <input className="w-50 liquid" type="text" name="net" disabled/>
                                    </div>
                                </div>
                            </div>
                            <div className="third-section pt-5">
                                <div className="input-container flex-row m-0 pb-1">
                                    <label className="py-05">Recebi (emos) de</label>
                                    <input className="flex-grow" type="text" name="tenant"/>
                                </div>
                                <div className="input-container flex-row m-0 pb-1">
                                    <label className="py-05">a importância de</label>
                                    <input className="flex-grow" type="text" name="importance"/>
                                </div>
                                <div className="input-container flex-row m-0 pb-1">
                                    <label className="py-05">Proveniente do aluguel d</label>
                                    <input className="flex-grow" type="text" name="provenance"/>
                                </div>
                                <div className="input-container flex-row m-0 pb-1">
                                    <label className="py-05">Sito à</label>
                                    <input className="flex-grow" type="text" name="site"/>
                                </div>
                                <div className="input-container flex-row m-0 pb-1">
                                    <label className="py-05">Referente ao período de</label>
                                    <input className="flex-grow" type="text" name="period"/>
                                    <label className="py-05">Vencido em</label>
                                    <input className="" type="date" name="due_date"/>
                                </div>
                                <div className="input-container flex-row m-0 pb-1">
                                    <label className="py-05">CPF/CNPJ</label>
                                    <input className="flex-grow" type="text" name="cpf_cnpj"/>
                                </div>
                                <div className="pt-5"></div>
                                <div className="input-container flex-row m-0 pb-1 py-10">
                                    <label className="py-05">Data</label>
                                    <input className="" type="date" name="date"/>
                                    <label className="py-05">Assinatura</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="button-container py-8">
                <input type="submit" className="form-btn m-0" value="Gerar PDF"/>
            </div>
        </form>
    )
}

const Receipt = (props) => {

    var isLoggedIn = localStorage.getItem('isLoggedIn');

    if(isLoggedIn === null || isLoggedIn === undefined){
        isLoggedIn = false;
    }

    if(!isLoggedIn){
        return <Navigate to="/login"/>;
    }

    dbQuery("get_last_receipt_number", {}, (data)=>{
        if(data.length !== undefined && data.length === 0){
            //alert("Erro ao buscar número do recibo.");
        }
        else{
            var { number } = document.getElementsByClassName("main-form")[0];
            number.value = Number(data.receipt_number) + 1;
        }
    });

    const saveReceipt = (event) => {
        event.preventDefault();
        
        var receipt_info = document.getElementsByClassName("main-form")[0];
        var { number } = document.getElementsByClassName("main-form")[0];

        //store every field into a json object with the field name as key
        var receipt = {};
        for(var i = 0; i < receipt_info.length; i++){
            receipt[receipt_info[i].name] = receipt_info[i].value;
        }

        var now_string = new Date().toISOString().slice(0, 19).replace("T", " ");

        //send the json object to the database
        dbQuery("save_receipt", {
            receipt_date: now_string,
            receipt_number: number.value,
            receipt_info: receipt,
        }, (data)=>{
            if(data === true){
                //alert("Recibo salvo com sucesso.");
            }
            else{
                alert("Erro ao salvar recibo.");
            }
        });

        //add css to remove all box shadows
        var style = document.createElement("style");
        style.innerHTML = ".receipt-form{box-shadow: none;}";
        document.head.appendChild(style);

        var node = document.getElementsByClassName("receipt-form")[0];

        domtoimage.toPng(node).then((dataUrl) => {
            var img = new Image();
            img.src = dataUrl;

            img.onload = function(){
                var imgWidth = 210;
                var pageHeight = 295;
                var imgHeight = img.height * imgWidth / img.width;
                var heightLeft = imgHeight;

                var pdf = new jsPDF("p", "mm");
                var position = 0;

                pdf.addImage(img, "PNG", 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;

                while(heightLeft >= 0){
                    position = heightLeft - imgHeight;
                    pdf.addPage();
                    pdf.addImage(img, "PNG", 0, position, imgWidth, imgHeight);
                    heightLeft -= pageHeight;
                }

                //calculate img height in pdf
                var imgHeightInPdf = img.height * imgWidth / img.width;

                //add signature
                var signatureWidth = 50;
                var signatureHeight = img.height * signatureWidth / img.width;
                var signatureX = 70;
                var signatureY = imgHeightInPdf - signatureHeight/2 - 10;

                pdf.addImage(assinaturaRecibo, "PNG", signatureX, signatureY, signatureWidth, signatureHeight);

                //remove css
                //style.remove();

                //save pdf
                pdf.save("recibo_aluguel.pdf");
            }
        }).catch((error) => {
            console.error("oops, something went wrong!", error);
        });
    };

    const sumValues = (event) => {
        var { rent, taxes, condominium, others, desc_irf } = document.getElementsByClassName("main-form")[0];

        var sum = 0;
        if(rent !== undefined && rent !== ""){
            sum += Number(taxes.value);
        }
        if(taxes !== undefined && taxes !== ""){
            sum += Number(rent.value);
        }
        if(condominium !== undefined && condominium !== ""){
            sum += Number(condominium.value);
        }
        if(others !== undefined && others !== ""){
            sum += Number(others.value);
        }
        if(desc_irf !== undefined && desc_irf !== ""){
            desc_irf = Number(desc_irf.value);
        }
        else{
            desc_irf = 0;
        }

        var sum_field = document.getElementsByClassName("sum")[0];
        sum_field.value = sum;

        var liquid = document.getElementsByClassName("liquid")[0];
        liquid.value = sum - sum*(desc_irf/100);
    };

    return (
        <div>
            <UpperMenu/>
            <div className="default-container-receipt receipt-default-container-container">
                <Content formFunction={saveReceipt} sumValues={sumValues}/>
            </div>
        </div>
    )
};

export default Receipt;