import React from "react";
import backArrow from '../images/backArrow.png';
import { useNavigate } from 'react-router-dom';

const PageForm = (props) => {

    const navigate = useNavigate();

    const backNavigation = (event) => {
        event.preventDefault();
        navigate('/login');
    };

    return (
        <div className={"main-content"}>
            {props.backNavigation &&
                <form onSubmit={backNavigation}>
                    <div className="row w-100 pb-3">
                        <div className="col">
                            <div className="flex-row headerBtn">
                                <div className="headerBtn flex-row">
                                    <input className="small-img disabled" type="image" alt="Anterior" src={backArrow}></input>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            }
            <div>
                <div className="title">{props.title}</div>
                {props.content}
            </div>
        </div>
    )
};

export default PageForm;