import React from "react";

import UpperMenu from "../components/UpperMenu.js"
import dbQuery from "../gets/DBQuery";
import PasswordValidation from "../components/PasswordValidation.js";

const Content = (props) => {

    var { method, formFunction } = props;

    return (
      <div className="main-content">
        <div className="title">Alterar senha</div>
        <form className="main-form" onSubmit={formFunction}>
          <div className="input-container">
              <input placeholder="Email" type="email" name="email" required />
          </div>
          {method === "token" &&
          <div className="input-container">
              <input placeholder="Código de recuperação" type="text" name="token" required/>
          </div>
          }
          {method === "pass" &&
          <div className="input-container">
              <input placeholder="Senha antiga" type="password" name="old_pass" required/>
          </div>
          }
          <div className="input-container">
              <input placeholder="Nova senha" type="password" name="new_pass" required/>
          </div>
          <div className="input-container">
              <input placeholder="Nova senha novamente" type="password" name="new_pass_again" required/>
          </div>
          <div className="w-100 button-container">
              <input type="submit" className="form-btn" value="Mudar senha" required/>
          </div>
        </form>
      </div>
    )
  }

const ChangeCredentials = (props) => {

    var isLoggedIn = localStorage.getItem('isLoggedIn');

    if(isLoggedIn === null || isLoggedIn === undefined){
        isLoggedIn = false;
    }

    var method = null;

    if(isLoggedIn){
        method = "pass";
    }
    else{
        method = "token";
    }

    const changePassword = (event) => {
        event.preventDefault();
    
        var { email, token, old_pass, new_pass, new_pass_again } = document.getElementsByClassName("main-form")[0];

        var alert_text = PasswordValidation(new_pass.value);

        if(alert_text !== ""){
            alert(alert_text);
            return;
        }

        if(new_pass.value !== new_pass_again.value){
            alert("As duas senhas precisam ser iguais.");
            return;
        }

        if(method === "token"){
            dbQuery("change_password_with_token", {
                email: email.value,
                token: token.value,
                new_pass: new_pass.value
            }, (data)=>{
                if(data === true){
                    alert("Senha alterada com sucesso.");
                }
                else{
                    alert("Email ou código incorretos, ou código expirado.");
                }
            });
        }
        else if(method === "pass"){
            dbQuery("change_password_with_pass", {
                email: email.value,
                old_pass: old_pass.value,
                new_pass: new_pass.value
            }, (data)=>{
                if(data === true){
                    alert("Senha alterada com sucesso.");
                }
                else{
                    alert("Email ou senha antiga incorretos.");
                }
            });
        }
    };

    return (
        <div>
            <UpperMenu/>
            <div className="default-container">
                <Content formFunction={changePassword} method={method}/>
            </div>
        </div>
    )
};

export default ChangeCredentials;