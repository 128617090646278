import React from "react";
import PageForm from "../components/PageForm";
import dbQuery from "../gets/DBQuery";
import { useNavigate } from "react-router-dom";

const Content = (props) => {
  return (
    <div>
      <form className="main-form" onSubmit={props.formFunction}>
        <div className="input-container">
            <input placeholder="Email" type="email" name="email" required />
        </div>
        <div className="w-100 button-container">
            <input type="submit" className="form-btn" value="Enviar Código"/>
        </div>
      </form>
    </div>
  )
}

const Recovery = (props) => {

    const navigate = useNavigate();

    var isLoggedIn = localStorage.getItem('isLoggedIn');

    if(isLoggedIn === null || isLoggedIn === undefined){
        isLoggedIn = false;
    }

    if(isLoggedIn){
        //redirect to home
        navigate("/home");
    }

    const sendRecCode = (event) => {
        event.preventDefault();
    
        var { email } = document.getElementsByClassName("main-form")[0];

        //send recovery code request

        //console.log(email.value);

        dbQuery("send_rec_token", {
            email: email.value
        }, (data)=>{
            if(data === true){
                alert("Código enviado para o email.");
                //send user to change password page
                navigate("/credentials");
            }
            else{
                alert("Email não encontrado ou código já enviado.");
            }
        });
    };

    return (
        <div>
            <div className="default-container">
                <div className="p-10">
                    <PageForm backNavigation={true} title={"Recuperação"} content={<Content formFunction={sendRecCode}/>}/>
                </div>
            </div>
        </div>
    )
};

export default Recovery;