import React from "react";

import "./styles.css";

import Router from "./routes";

function App() {
  return (
    <Router/>
  );
}

export default App;

export const db_base_url = "https://sistemareciboaluguel-backend.twinfo.com.br/public_html/";