import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import Receipt from "./routes/Receipt.js";
import Login from "./routes/Login.js";
import ChangeCredentials from "./routes/ChangeCredentials.js";
import Recovery from "./routes/Recovery.js";
import Registration from "./routes/Registration.js";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Receipt/>
  },
  {
    path: "/home",
    element: <Receipt/>
  },
  {
    path: "/receipt",
    element: <Receipt/>
  },
  {
    path: "/login",
    element: <Login/>
  },
  {
    path: "/register",
    element: <Registration/>
  },
  {
    path: "/credentials",
    element: <ChangeCredentials/>
  },
  {
    path: "/recovery",
    element: <Recovery/>
  },
  // {
  //   path: "*",
  //   element: <NotFound/>
  // },
])

const Router = (props) => {

    return(
        <RouterProvider router={router} />
        /*<React.StrictMode>
            
        </React.StrictMode>*/
    )
}

export default Router;